import React from 'react';
import Works from '../../components/works';
import { works_tr } from "../../data/works"

const i18n = {
    description: (
        <p>
            Çalışmalarım
        </p>
    ),
    works: works_tr,
    link_desc: "Çalışmayı Gör"
};

export default (props) => <Works i18n={i18n} {...props} />;
